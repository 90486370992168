<template>
  <component :is="modalComponent" @close="onClose" ref="modal" modal-child-rep-expire class="custom-form">
    <svg-fail class="icon-exclamation" />
    <h1 class="modal-title">결제 동의 기간 만료</h1>
    <p class="description">
      <span class="desc-normal">법정대리인 결제 동의 기간이 지났습니다.</span>
      <br />
      <span class="desc-warn">다시 보호자의 동의를 받아 주세요.</span>
    </p>
    <hr />
    <p class="date-label">동의 기간</p>
    <p class="date-content">
      {{ timeText }}
    </p>
    <template #floating>
      <primary-button color="red" class="btn-modal last-confirm" @click="onAgree">보호자 동의 받기</primary-button>
    </template>
  </component>
</template>

<script>
import SvgFail from '@shared/graphics/svg-fail.vue';
import Modal from '@shared/components/common/Modal.vue';
import PrimaryButton from '@shared/components/common/PrimaryButton.vue';

export default {
  components: { SvgFail, PrimaryButton },
  props: {
    modalComponent: {
      type: Object,
      default: () => Modal,
    },
    /** @type{LegalRepresentative} */
    options: {
      type: Object,
      default: () => ({
        agreeDateTime: 0,
        expireDateTime: 0,
        userId: '',
      }),
    },
  },
  computed: {
    timeText() {
      const startText = this.$date(this.options?.agreeDateTime, 'dot');
      const endText = this.$date(this.options?.expireDateTime, 'dot');
      return `${startText} ~ ${endText}`;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onAgree() {
      this.$emit('resolve');
    },
  },
  beforeDestroy() {
    if (this?.$refs?.modal?.close) this.$refs.modal.close();
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[modal-child-rep-expire] {
  .icon-exclamation {fill: @c-red-strong;.wh(24, 27.15);.ml(auto);.mr(auto);.block;}
  .modal-title {text-align: center;.fs(20);.lh(20);.mt(14.85);.bold;}
  .description {.fs(14);.lh(20);text-align: center;.mt(24);font-weight: 500;.mb(24)}
  .desc-normal {.c(@c-title-black)}
  .desc-warn {.c(@c-red-strong)}
  .date-label {.mt(24)}
  .date-content {.mt(8);.bgc(#f7f7fb);.pt(30);.pb(30);text-align: center;.mb(40);.c(@c-title-black);.fs(14);.lh(100%);.bold;.noto;}
  [primary-button] {.ml(20);.mr(20);.mb(40);.w(calc(100% - 40px));.fs(16)}
}
</style>
